import { Component, OnInit } from '@angular/core';
import { FormsService } from '../forms.service';
import { NavParams, LoadingController } from '@ionic/angular';
import { UserService } from '../user.service';
import { AppcmsService } from '../appcms.service';
import { EventsService } from '../events.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
})
export class FeedbackPage implements OnInit {

  form: any;

  formConfig: any = {
    validated: false,
  };

  formId: number = 82165;

  modalCtrl: any;

  user: any;

  constructor(
    public AppCMS: AppcmsService,
    public events: EventsService,
    public formsService: FormsService,
    public loadingCtrl: LoadingController,
    public navParams: NavParams,
    public userService: UserService,
  ) {
    this.modalCtrl = this.navParams.get('modalCtrl');
    this.user = this.userService.getUser() || {};
    this.loadForm();
  }

  _onChange() {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ionViewWillEnter() {
    this.AppCMS.resetApiUrl();
    this.user = this.userService.getUser() || {};
  }
  
  loadForm() {
    this.formsService.getByUid(this.formId)
    .then((form: any) => {
      this.form = form;
    })
    .catch(error => {
      this.events.publish('error', error);
    });
  }

  ngOnInit() {
  }

  onChange(rating: number, iField: number) {
    this.form.fields[iField].answer = rating;

    // @todo validate "required" key of each field
    this.formConfig.validated = true;
  }

  async submit() {
    let loading = await this.loadingCtrl.create();
    loading.present();

    this.formsService.submit(this.form)
    .then((response: any) => {
      loading.dismiss();
      this.events.publish('appcms:alert', {
        title: 'Dankeschön!',
        message: 'Vielen Dank für die Rückmeldung! Wir werden es nutzen, um uns weiter zu verbessern.',
      });
      this.dismiss();
    })
    .catch(error => {
      loading.dismiss();
      this.events.publish('error', error);
    });
  }

}
