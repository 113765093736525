import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { BasketService } from './basket.service';
import { WoocommerceextendService } from './woocommerceextend.service';
import { OrdersService } from './orders.service';

import * as moment from 'moment';
import { EventsService } from './events.service';
import { NavController } from '@ionic/angular';
import { CacheService } from './cache.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  deliveryOptions: any = window.localStorage.getItem('deliveryOptions') ? JSON.parse(window.localStorage.getItem('deliveryOptions')) : null;

  pendingOrder: any = window.localStorage.getItem('pendingOrder') ? JSON.parse(window.localStorage.getItem('pendingOrder')) : null;
  orderFormData: any; basketInfo: any = {};
  constructor(
    public AppCMS: AppcmsService,
    public basket: BasketService,
    public cache: CacheService,
    public events: EventsService,
    public orders: OrdersService,
    public navCtrl: NavController,
    public woocommerce: WoocommerceextendService,
    public router : Router
  ) 
  {
   
  }

  afterSuccessPayCODInvoice(order: any = {}) {
    let method = 'checkout'; //this.deliveryOptions.repeat && order && order.period ? 'checkoutScheduled' : 'checkout';
    if (order.Payment == 'PayPal') {
      
      this.basketInfo = this.basket.calculateBasketInfo();
       let orderData = JSON.parse(window.localStorage.getItem('orderData'));
       let miniumorderamount = window.localStorage.getItem('miniumorderamount');
       let appcmsUser = JSON.parse(window.localStorage.getItem('appcmsUser'));
       let currentStore = JSON.parse(window.localStorage.getItem('current_store'));
       let Paypalsubscriptionplanid = window.localStorage.getItem('Paypalsubscriptionplanid');
       let note = window.localStorage.getItem("notes");
       let isQrcodeScanned= window.localStorage.getItem("isQrcodeScanned");
       let qrcodename = window.localStorage.getItem('type');
       if(window.localStorage.getItem('type') =="Tischnummer")
       {
         orderData.deliveryDate = moment(new Date).format("MM-DD-YYYY");
         orderData.deliveryTime = moment().format('HH:mm');
       }

       let phone ='';
       let uid = '';
       if(appcmsUser){
        phone =  appcmsUser?.phone!=null?appcmsUser?.phone:'';
        uid =  appcmsUser?.uid!=null?appcmsUser?.uid:'';
       }

       const processedData = this.basket.basketStorage.map(item => {
        // Check if "categories" is an array
        if (Array.isArray(item.categories)) {
          // Convert array to single value
          return { ...item, categories: item.categories[0] };
        } else {
          // Otherwise, return the item as it is
          return item;
        }
      });
       this.orderFormData = {
           status: "Received", 
           type:orderData.repeat,
           Payment: "PayPal", 
           address: orderData.address,
           street: orderData.street,
           streetno: orderData.streetno,
           zipcode: orderData.zipcode,
           city: orderData.city,
           country: orderData.country,
           Deliverydatetime: orderData.deliveryDate+' '+orderData.deliveryTime,
           Orderplacedtime: moment().format("MM-DD-YYYY HH:mm"),
           deliveryType: orderData.deliveryType,
           phone: phone,
           price: order.price,
           MemberId: uid,      
           BranchId: currentStore.uid,
           CouponID:order.couponid,
           Coupondiscount:order.Coupondiscount,
           Coupondescription:order.Coupondescription,
          //  ProductList: JSON.stringify(this.basket.basketStorage),
           ProductList: JSON.stringify(processedData),
           note: note,
           miniumorderamount: miniumorderamount,
           deliverycharge: 0,
           basketAmount: this.basketInfo.price_a,
           isqrcodeorder: window.localStorage.getItem("isQrcodeScanned")=="false"||window.localStorage.getItem("isQrcodeScanned")==null?false:true,
           qrcodename: qrcodename == "Platznummer"? "Platz-Bestellung" : qrcodename == "Tischnummer"? "Tisch-Bestellung": qrcodename == "Zimmernummer"? "Zimmer-Bestellung":"",
           tableNumber : window.localStorage.getItem('tnDuplicate'),
           Qrcodeid: window.localStorage.getItem("qrcodeid"),


          //  transactionId : order.id,
          //  paymentStatus : order.status,
          //  transactionCode: order.purchase_units[0].payments.captures[0].id
       };            
       
       if(orderData.repeat != 0)  { // subscription
        this.orderFormData.transactionId = order.orderID;
        this.orderFormData.paymentStatus = "Success";
        this.orderFormData.transactionCode = order.subscriptionID;
        this.orderFormData.Paypalsubscriptionplanid = Paypalsubscriptionplanid;
       } else {
          this.orderFormData.transactionId = order.id;
          this.orderFormData.paymentStatus = order.status;
          // this.orderFormData.transactionCode= order.purchase_units[0].payments.captures[0].id;
       }
    } 
    return new Promise((resolve, reject) => {
      order.note = window.localStorage.getItem("notes")?.trim();
      this[method](order.Payment == 'PayPal' ? this.orderFormData: order).then((response: any) => {
        let isQrcodeScanned= window.localStorage.getItem("isQrcodeScanned");
        // console.log("isQrcodeScanned",isQrcodeScanned)
        if(isQrcodeScanned == "true")
        this.navCtrl.navigateRoot('confirmation',{ state: { data: response } });
        else
        this.navCtrl.navigateRoot('on-delivery');
        resolve(response);
      }).catch(reject);
    });
    
  }

  afterSuccessPay(order: any = {}) {
    return new Promise((resolve, reject) => {
      let method = 'checkout'; //this.deliveryOptions.repeat && order && order.period ? 'checkoutScheduled' : 'checkout';
      // let navPage = method == 'checkoutScheduled' ? '/planner' : '/on-delivery';


       //***** Append paypal data to order form data  ********/   
       this.basketInfo = this.basket.calculateBasketInfo();
       let orderData = JSON.parse(window.localStorage.getItem('orderData'));
       let miniumorderamount = window.localStorage.getItem('miniumorderamount');
       let appcmsUser = JSON.parse(window.localStorage.getItem('appcmsUser'));
       let currentStore = JSON.parse(window.localStorage.getItem('current_store'));
       let Paypalsubscriptionplanid = window.localStorage.getItem('Paypalsubscriptionplanid');
       let note = window.localStorage.getItem("notes");
       let isQrcodeScanned= window.localStorage.getItem("isQrcodeScanned");
       let qrcodename = window.localStorage.getItem('type');
       if(window.localStorage.getItem('type') =="Tischnummer")
       {
         orderData.deliveryDate = moment(new Date).format("MM-DD-YYYY");
         orderData.deliveryTime = moment().format('HH:mm');
       }
       let phone ='';
       let uid = '';
       if(appcmsUser){
        phone =  appcmsUser?.phone!=null?appcmsUser?.phone:'';
        uid =  appcmsUser?.uid!=null?appcmsUser?.uid:'';
       }
       this.orderFormData = {
           status: "Received", 
           type:orderData.repeat,
           Payment: "PayPal", 
           address: orderData.address,
           street: orderData.street,
           zipcode: orderData.zipcode,
           city: orderData.city,
           country: orderData.country,
           Deliverydatetime: orderData.deliveryDate+' '+orderData.deliveryTime,
           Orderplacedtime: moment().format("MM-DD-YYYY HH:mm"),
           deliveryType: orderData.deliveryType,
           phone:phone,
           price: order.price,
           MemberId: uid,      
           BranchId: currentStore.uid,
           CouponID:orderData.couponId,
           ProductList: JSON.stringify(this.basket.basketStorage),
           note: note,
           miniumorderamount: miniumorderamount,
           deliverycharge: 0,
           basketAmount: this.basketInfo.price_a,
           isqrcodeorder: window.localStorage.getItem("isQrcodeScanned")=="false"||window.localStorage.getItem("isQrcodeScanned")==null?false:true,
           qrcodename: qrcodename == "Platznummer"? "Platz-Bestellung" : qrcodename == "Tischnummer"? "Tisch-Bestellung": qrcodename == "Zimmernummer"? "Zimmer-Bestellung":"",
           tableNumber : window.localStorage.getItem('tnDuplicate'),
           Qrcodeid: window.localStorage.getItem("qrcodeid"),

          //  transactionId : order.id,
          //  paymentStatus : order.status,
          //  transactionCode: order.purchase_units[0].payments.captures[0].id
       };            
       
       if(orderData.repeat != 0)  { // subscription
        this.orderFormData.transactionId = order.orderID;
        this.orderFormData.paymentStatus = "Success";
        this.orderFormData.transactionCode = order.subscriptionID;
        this.orderFormData.Paypalsubscriptionplanid = Paypalsubscriptionplanid;
       } else {
          this.orderFormData.transactionId = order.id;
          this.orderFormData.paymentStatus = order.status;
          this.orderFormData.transactionCode= order.purchase_units[0].payments.captures[0].id;
       }

       //***** Append paypal data to order form data  ********/
      //console.log(this.orderFormData);
      // console.log('in method to check--->',method)
      this.navCtrl.navigateRoot('checkout');
     
    });
  }

  checkout(order) {
    return new Promise((resolve, reject) => {
     
      
      this.woocommerce.buy(
        order
      )
      .then((response: any) => {
        //console.log(response);
        resolve(response);
        
      })
      .catch(reject);
    });
  }

  checkoutScheduled() {
    return new Promise((resolve, reject) => {

      let basket = this.basket.getBasket();
      basket.forEach((basketItem: any, index: number) => {
        delete basket[index].metaData;
      });
  
      this.woocommerce.schedulePlannedOrder(
        basket
      )
      .then((response: any) => {
        if(response.hasOwnProperty('create') && response.create) {
          let order = response.create.create || null;
          if(order) {
            this.setDeliveryOptions({});
            this.setPendingOrder(order);
            this.orders.addOrder(order)
            .then((response: any) => {
              this.basket.clear();
              resolve(response);
            })
            .catch(reject);
          }
        }
        this.getPendingOrder().then(resolve).catch(reject);
      })
      .catch(reject);
    });
  }

  getDeliveryOptions() {
    if(this.deliveryOptions && this.deliveryOptions.type === 'now' && !this.deliveryOptions.datetime) {
      this.deliveryOptions.datetime = moment().format('DD.MM.YYYY') + ' ' + moment().format('HH:mm');
    }
    return this.deliveryOptions;
  }

  getPendingOrder() {
    return new Promise((resolve, reject) => {
      if(this.pendingOrder) {
        this.woocommerce.getOrderByUid(this.pendingOrder.id)
        .then((order: any) => {
          switch(order.status) {
            case 'completed':
              order.status_formatted = 'Fertig';
              break;
            case 'on-hold':
              order.status_formatted = 'In Zustellung';
              break;
            case 'processing':
              order.status_formatted = 'In Bearbeitung';
              break;
            case 'trash':
              order.status_formatted = 'Papierkorb';
              break;
            default:
              order.status_formatted = 'Ausstehend';
              break;
          }
          resolve(order);
        }).catch(reject);
      } else {
        reject('Keine Bestellung in Bearbeitung');
      }
    });
  }

  orderFailed(error: string = 'Ein unbekannter Fehler ist aufgetreten') {
    this.events.publish('error', error);
  }

  setDeliveryOptions(options: any) {
    this.deliveryOptions = options;
    this.cache.set('deliveryOptions', this.deliveryOptions);
    this.cache.set('orderData', this.deliveryOptions);
    this.events.publish('checkout:deliveryOptions:updated', this.deliveryOptions);
  }

  setPendingOrder(order: any) {
    this.pendingOrder = order;
    this.cache.set('pendingOrder', this.pendingOrder);
  }


  CheckPaymentStatus(id) {
    return new Promise((resolve, reject) => {
        this.AppCMS.postPluginData(`MolliePayment/CheckPaymentStatus?${id}`)
            .then((response: any) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(response.message);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

  

}