import { Injectable } from '@angular/core';

import { Network } from '@ionic-native/network/ngx';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  blConnected: boolean = false;

  constructor(
    public network: Network,
  ) {

  }

  getType() {
    return this.network.type;
  }

  isConnected() {
    return this.blConnected;
  }

  onConnect() {
    return this.network.onConnect();
  }

  onDisconnect() {
    return this.network.onDisconnect();
  }

  watch() {

    this.network.onConnect()
    .subscribe(() => {
      this.blConnected = true;
    });

    this.network.onDisconnect()
    .subscribe(() => {
      this.blConnected = false;
    });

  }

}
