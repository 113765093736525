import { Component, OnInit } from '@angular/core';
import { WoocommerceextendService } from '../woocommerceextend.service';
import { EventsService } from '../events.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-coupon-validate',
  templateUrl: './coupon-validate.page.html',
  styleUrls: ['./coupon-validate.page.scss'],
})
export class CouponValidatePage implements OnInit {

  validateConfig: any = {
    loaded: false,
  };

  constructor(
    public events: EventsService,
    public woocommerce: WoocommerceextendService,
    public route: ActivatedRoute,
  ) {
    this.validateConfig.code = this.route.snapshot.paramMap.get('couponCode');
    this.loadCode();
  }

  loadCode() {
    this.woocommerce.getCouponByCode(this.validateConfig.code)
    .then((response: any) => {
      if(response.coupon) {
        this.woocommerce.useCoupon(response.coupon)
        .then(() => {
          this.validateConfig.validated = true;
          this.validateConfig.coupon = response.coupon;
        })
        .catch(error => {
          this.validateConfig.validated = false;
          this.events.publish('error', error);
        });
      }
    })
    .catch(error => {
      this.validateConfig.validated = false;
      this.events.publish('error', error);
    });
  }
  ngOnInit() {
  }

}
