import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  configOptions: any = [
    {
      checked: true,
      default: true,
      indent: 'gurke',
      title: 'Gurke',
      type: 'checkbox',
    },
    {
      checked: true,
      default: true,
      indent: 'salat',
      title: 'Salat',
      type: 'checkbox',
    },
    {
      checked: true,
      default: true,
      indent: 'tomate',
      title: 'Tomate',
      type: 'checkbox',
    },
  ];

  constructor(

  ) {

  }

  getConfigOptions() {
    return this.configOptions;
  }

  getConfigurationItems(product: any) {
    if(product.amount && product.amount > 0) {
      let items: any = [], i = 0;
      while(i < product.amount) {
        let price = (product._price || product.price) / product.amount;
        items.push({
          title: '#' + (i+1),
          price: price,
          price_html: price.toFixed(2) + '€',
          options: JSON.parse(JSON.stringify(this.getConfigOptions())),
        });
        i++;
      }
      return items;
    }
  }


  getConfiguratorSettings(product: any) {
    
    if(!product._items) {
      product._items = JSON.parse(JSON.stringify(product.items));
    }

    if(!product._price) {
      product._price = product.price;
    }

    let settings: any = {
      count: 0,
      max: 0,
      items: [],
      pricePerItem: 0,
    };

    product._items.forEach((_item: any, index: number) => {
      settings.max += product._items[index].amount;
    });

    settings.pricePerItem = parseFloat(product._price) / settings.max;

    product.items.forEach((item: any, index: number) => {
      item.price = (item.amount || 1) * settings.pricePerItem;
      item.price_html = item.price.toFixed(2) + '€';
      item.configItems = this.getConfigurationItems(item); 

      settings.count += product.items[index].amount;
      settings.items.push(item);
    });

    settings._items = product._items;
    settings.price = settings.pricePerItem * settings.count;
    settings.price_html = settings.price.toFixed(2) + '€';
    
    return settings;
  }

}
