import { getPlatform, Injectable } from '@angular/core';
import { EventsService } from './events.service';
import { AppcmsService } from './appcms.service';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  current: string = null;
  public tenantId: string;
  public domainName:string;
  constructor(
    public events: EventsService,
    public AppCMS: AppcmsService,
    public platform: Platform
  ) {
  this.getDomainNameAndPlatform()
  }


  getDomainNameAndPlatform(){
    
    let getUrl = window.location.href;
    let domain = (new URL(getUrl));
    if(this.platform.is('desktop')){
      this.domainName = domain.host;
      console.log(this.domainName)
    } else{
      if (environment.production) {
        this.domainName="app.broetchen.taxi"
        }
      else {
          this.domainName="app-staging.broetchen.taxi"
          }
    }
  }
  update(themeSettings: themeSettings) {
    if(!this.current || (this.current !== themeSettings.name)) {
      //console.log('> theme: update', themeSettings);

      document.body.style.setProperty('--ion-color-primary', themeSettings.colors.primary);
      document.body.style.setProperty('--ion-color-secondary', themeSettings.colors.secondary);

      document.body.style.setProperty('--bullet-background-active', `var(--ion-color-primary, ${themeSettings.colors.primary})`);

      this.current = themeSettings.name;
    }
  }

  getTheme(){
    //console.log('=================Inside service===============')
    return new Promise((resolve, reject) => {
        this.AppCMS.loadUrl('SystemBranding/'+ this.domainName)
          .then((response: any) => {
            resolve(response);
          })
          .catch(reject);
    });
  }

}
