import { Injectable } from '@angular/core';
// import {AuthService} from '../authentication/auth.service';
import {CanActivate} from '@angular/router';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router, 
    // private authService: AuthService
    ) { }

    // canActivate(): boolean {
    //     if(this.authService.isAuthenticated()){
    //         // this.router.navigate(['/whateverpage'])
    //         return false;
    //     }
    //     return true;
    // }
}
