import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { BasketService } from './basket.service';

import * as $ from 'jquery';
import * as moment from 'moment';

import { UserService } from './user.service';
import { EventsService } from './events.service';
import { OrdersService } from './orders.service';
import { CacheService } from './cache.service';
import { TaxiService } from './taxi.service';

@Injectable({
  providedIn: 'root'
})
export class WoocommerceextendService {

  /**
   * @deprecated
   */
  categoriesByUid: category[] = window.localStorage.getItem('categoriesByUid') ? JSON.parse(window.localStorage.getItem('categoriesByUid')) : {};
  
  /**
   * @deprecated
   */
  products: product[] = window.localStorage.getItem('products') ? JSON.parse(window.localStorage.getItem('products')) : null;
  
  /**
   * @deprecated
   */
  productsByCategory: product[] = window.localStorage.getItem('productsByCategory') ? JSON.parse(window.localStorage.getItem('productsByCategory')) : {};
  
  /**
   * @deprecated
   */
  productsByUid: product[] = window.localStorage.getItem('productsByUid') ? JSON.parse(window.localStorage.getItem('productsByUid')) : {};

  /**
   * Stores the information about other orders at current time slot
   */
  workload: workload;

  _deliveryOptions: deliveryOptions;

  constructor(
    public AppCMS: AppcmsService,
    public basket: BasketService,
    public taxi: TaxiService, 
    public cache: CacheService,
    public events: EventsService,
    public ordersService: OrdersService,
    public userService: UserService,
  ) {
    this.events.subscribe('checkout:deliveryOptions:updated', (_deliveryOptions: any) => {
      this._deliveryOptions = _deliveryOptions;
      if (this.products) {
        this.parseProducts(this.products)
          .then((products: product[]) => {
            this.products = products;
          })
          .catch((error: any) => {
            this.events.publish('error', error);
          });
      }
    });
  }

  buy(basketStorage: any) {

    //let Articles = [];
    // basketStorage.forEach((Article: any) => {
    //   Articles.push(this.basket.toBasketItem(Article));
    // });

    // let params = {
    //   basket: Articles,
    //   basketInfo: this.basket.calculateBasketInfo(basketStorage),
    //   user: this.userService.getUser(),
    // };
    return this.AppCMS.loadPluginData(
      'Order',
      basketStorage,     
    );
  }

  clearCache(prefetch: boolean = false) {
    this.getCategories()
      .then((categories: category[]) => {
      
        if(categories && categories.length) {
          categories.forEach((category: category) => {
            window.localStorage.removeItem('productsByCategory_' + category.id + '_' + this.AppCMS.getApiUrl());
          });
        }

        if(!prefetch) {
          window.localStorage.removeItem('products' + this.AppCMS.getApiUrl());
          window.localStorage.removeItem('productsByUid_' + this.AppCMS.getApiUrl());
          window.localStorage.removeItem('categoriesByUid_' + this.AppCMS.getApiUrl());
          window.localStorage.removeItem('woocommerceCategories_');
        }

        if (prefetch) {
          this.getProducts()
            .then(() => {
              this.getCategories()
                .then((categories: any) => { console.warn('loaded categories', categories); })
                .catch(error => {
                  console.warn('prefetch getCategories error', error);
                });
            })
            .catch(error => {
              console.warn('prefetch getProducts error', error);
            });
        }
      })
      .catch(error => {
        console.warn('error', error);
      });
  }

  createCoupon(coupon: coupon) {
    return this.AppCMS.loadPluginData('woocommerceextend', {
      coupon: coupon,
    }, ['coupons', 'create']);
  }

  filterCategories(categories: category[]) {

    categories.forEach((category: category, index: number) => {
      category.sort = category.sort || index;
    });

    categories = categories.filter((category: category) => {

      // @debug
      // Christian uses numbers in front of the category name for sorting,
      // because woocommerce does not provide any option for sorting categories
      // @todo improve this with an sort key in the App CMS backend
      if(category.name && category.name[2] === ' ') {
        category.name = category.name.substr(3);
      }
      
      if(category.name && category.name[0] === '.') {
        category.name = category.name.substr(1);
      }

      return category.id != 17 && category.id != 18 && category.id != 60;
    });

    return categories;
  }

  filterTodayAvailable(products: any) {
    return products.filter((product: any) => {
      return product.todayAvailable;
    });
  }

  getCategories(blForceReload: boolean = false) {
    //console.log('getCategories', blForceReload);

    return new Promise((resolve, reject) => {
      let key = 'woocommerceCategories_';
      let categoriesFromCache: cacheItem = this.cache.get(key, (60 * 60));
      
      //console.log('> categoriesFromCache', categoriesFromCache);

      // get cached categories
      // if (!blForceReload && (categoriesFromCache && categoriesFromCache.data)) {
      //   resolve(this.filterCategories(categoriesFromCache.data));
      //   // reload if required or no categories set
      // } else {
        //console.log('reloading categories');
        let branch_id = JSON.parse(window.localStorage.getItem('current_store')).uid;
        let orderDate = JSON.parse(window.localStorage.getItem('orderData'));
        this.AppCMS.loadUrl('Category/GetCategorybyBranch/'+branch_id+'/'+orderDate.deliveryDate+'/'+orderDate.deliveryType)
          .then((categories: any) => {
            this.cache.set(key, categories);
            resolve(categories);
            // resolve(this.filterCategories(categories));
          })
          .catch(reject);
      // }
    });
  }

  getCategoryByUid(categoryId: number) {
    return new Promise((resolve, reject) => {
      this.getCategories()
        .then(categories => {

          let cachedCategorySelect = Object.keys(categories).filter(iCategory => {
            let category = categories[iCategory];
            return category.uid == categoryId;
          }), cachedCategory = cachedCategorySelect[0] ? categories[cachedCategorySelect[0]] : null;

          if (cachedCategory) {
            resolve(cachedCategory);
          } else {
            reject('Kategorie nicht gefunden');
          }

        })
        .catch(reject);
    });
  }

  getCoupons() {
    return this.AppCMS.loadPluginData('woocommerceextend', {}, ['coupons']);
  }

  getCouponByCode(code: string) {
    return this.AppCMS.loadPluginData('woocommerceextend', {
      code: code,
    }, ['coupons', 'validate']);
  }

  getCouponByUid(couponId: number) {
    return this.AppCMS.loadPluginData('woocommerceextend', {}, ['coupons', couponId]);
  }

  getProducts(categoryId:any = {}, options: any = {}, blForceReload: boolean = false) {
    options.limit = options.limit || 100;
    
    return new Promise((resolve, reject) => {
      let key = 'products_' + JSON.stringify(options) + '_' + this.AppCMS.getApiUrl();
      let productsFromCache: cacheItem = this.cache.get(key, (60 * 60));
      
      if (!blForceReload && (productsFromCache && productsFromCache.data)) {
        this.parseProducts(productsFromCache.data, options)
          .then((parsedProducts: product[]) => {
            this.cache.set(key, parsedProducts);
            resolve(parsedProducts);
          })
          .catch(reject);
      } else {
        let branch_id = JSON.parse(window.localStorage.getItem('current_store')).uid;
        let orderDate = JSON.parse(window.localStorage.getItem('orderData'));
        this.AppCMS.loadUrl('Product/GetavailableProduct/'+branch_id+'/'+categoryId+'/'+orderDate.deliveryDate+'/'+orderDate.deliveryType)
          .then((products: any) => {
            resolve(products);
            // this.parseProducts(products, options)
            //   .then((parsedProducts: product) => {
            //     this.cache.set(key, parsedProducts);
            //     resolve(parsedProducts);
            //   })
            //   .catch(reject);
          })
          .catch(reject);
      }
    });
  }

  

  getProductsByCategory(iCategoryId: number, blForceReload = false) {
    return new Promise((resolve, reject) => {
      let key = 'productsByCategory_' + iCategoryId + '_' + this.AppCMS.getApiUrl();
      let productsByCategoriesFromCache: cacheItem = this.cache.get(key, (60 * 60));
      let options = {
        limit: 100,
      };

      if (!blForceReload && productsByCategoriesFromCache && productsByCategoriesFromCache.data) {
        resolve(productsByCategoriesFromCache.data);
      } else {
        let branch_id = JSON.parse(window.localStorage.getItem('current_store')).uid;
        let orderDate = JSON.parse(window.localStorage.getItem('orderData'));
        this.AppCMS.loadUrl('Product/GetavailableProduct/'+branch_id+'/'+iCategoryId+'/'+orderDate.deliveryDate+'/'+orderDate.deliveryType)
          .then(products => {
            resolve(products);
            // this.parseProducts(products)
            //   .then((products: any) => {
            //     this.cache.set(key, products);
            //     resolve(products);
            //   })
            //   .catch(reject);
          })
          .catch(reject);
      }
    });
  }

  getProductByUid(productId: number) {
    let orderDate = JSON.parse(window.localStorage.getItem('orderData'));
    return new Promise((resolve, reject) => {this.AppCMS.loadUrl('Product/'+productId+'/'+orderDate.deliveryType)
    .then((response: product) => {
      resolve(response);
    })
    .catch(error => {
      console.warn('error', error);
      reject('Produkt nicht gefunden');
    });
      // this.getProducts()
      //   .then((products: product[]) => {
      //     let cacheKey = 'productsByUid_' + this.AppCMS.getApiUrl();

      //     let cachedProductSelect = Object.keys(products).filter(iProduct => {
      //       let product = products[iProduct];
      //       if (product) {
      //         return product.id == productId;
      //       }
      //     });
          
      //     let cachedProduct = cachedProductSelect[0] && products[cachedProductSelect[0]] ? products[cachedProductSelect[0]] : this.cache.get(cacheKey, (60 * 60));
          
      //     if (cachedProduct) {
            
      //       if(cachedProduct.data) {
      //         cachedProduct = cachedProduct.data;
      //       }

      //       resolve(cachedProduct);
      //     } else
      //       if (this.productsByUid.hasOwnProperty(productId)) {
      //         resolve(this.productsByUid[productId]);
      //       } else {
      //         this.AppCMS.loadUrl('Product/'+productId)
      //           .then((response: product) => {
      //             this.productsByUid[productId] = response;
      //             this.cache.set(cacheKey, this.productsByUid);
      //             resolve(response);
      //           })
      //           .catch(error => {
      //             console.warn('error', error);
      //             reject('Produkt nicht gefunden');
      //           });
      //       }

      //   })
      //   .catch(reject);
    });
  }

  getProductVariations(productId: number, iDelay: number = 0) {
    return new Promise((resolve, reject) => {
      let key = 'productVariationsByUid_' + productId + '_' + this.AppCMS.getApiUrl();
      let cachedProductVariations: cacheItem = this.cache.get(key, (60 * 60));
      
      if (cachedProductVariations && cachedProductVariations.data) {
        resolve(cachedProductVariations.data);
      } else {
        setTimeout(() => {
          this.AppCMS.loadPluginData('woocommerceextend', {}, ['products', productId, 'variations'])
            .then((response: productVariation[]) => {
              //console.log('variations by product', productId, response);
              this.cache.set(key, response);
              resolve(response);
            })
            .catch(error => {
              console.warn('error', error);
              reject('Produkt-Variationen nicht gefunden');
            });
        }, iDelay);
      }
    });
  }

  getScheduledOrders() {
    return this.AppCMS.loadPluginData('woocommerceextend', {
      user: this.userService.getUid(),
    }, ['getScheduledOrders']);
  }
  
  getOrderByUid(orderId: number) {
    return this.AppCMS.loadPluginData('woocommerceextend', {}, ['order', orderId]);
  }

  getShippingZones() {
    return this.AppCMS.loadPluginData('woocommerceextend', {}, ['shippingZones']);
  }

  getShippingZoneLocations(zoneId: number) {
    return new Promise((resolve, reject) => {
      let cacheKey = this.AppCMS.getApiUrl() + '/woocommerceextend/shippingZones/' + zoneId + '/locations';
      let cachedLocations: cacheItem = this.cache.get(cacheKey, (60 * 60));

      if (cachedLocations && cachedLocations.data) {
        resolve(cachedLocations.data);
      } else {
        this.AppCMS.loadPluginData('woocommerceextend', {}, ['shippingZones', zoneId, 'locations'])
          .then(locations => {
            this.cache.set(cacheKey, JSON.stringify(locations));
            resolve(locations);
          })
          .catch(reject);
      }
    });
  }

  getShippingZoneMethods(zoneId: number, apiUrl: string = null) {
    return new Promise((resolve, reject) => {
      let cacheKey = (apiUrl || this.AppCMS.getApiUrl()) + '/woocommerceextend/shippingZones/' + zoneId + '/methods';
      let cachedMethods: cacheItem = this.cache.get(cacheKey, (60 * 60));
      let _apiUrl = this.AppCMS.getApiUrl();

      //console.log('cacheKey', cacheKey);
     // console.log('cachedMethods', cachedMethods);

      if (apiUrl) {
        this.AppCMS.setApiUrl(apiUrl);
      }

      if (cachedMethods && cachedMethods.data) {
        resolve(cachedMethods.data);
      } else {
        this.AppCMS.loadPluginData('woocommerceextend', {}, ['shippingZones', zoneId, 'methods'])
          .then(methods => {
            this.cache.set(cacheKey, methods);

            if (_apiUrl) {
              this.AppCMS.setApiUrl(_apiUrl);
            }

            resolve(methods);
          })
          .catch(reject);
      }
    });
  }

  getVariationByProduct(product: product) {
    let attributesFilter = {};

    if (product.metaData) {
      Object.keys(product.metaData).forEach((metaDataKey: string) => {
        let metaDataValue = product.metaData[metaDataKey];
        if (metaDataKey[0] !== '_') {
          attributesFilter[metaDataKey] = metaDataValue;
        }
      });
    }

    let filteredVariationFilter = product.variations.filter((_variation: any) => {
      let blMatch = true;
      if (_variation.attributes && _variation.attributes.length) {
        _variation.attributes.forEach((_attribute: any) => {
          if (attributesFilter.hasOwnProperty(_attribute.name)) {
            blMatch = blMatch ? attributesFilter[_attribute.name].indexOf(_attribute.option) !== -1 : false;
          }
        });
      }
      return blMatch;
    });
    let filteredVariation = filteredVariationFilter[0] || null;

    //console.log('apply metaData', product.metaData);

    // add missing data from parent to variant
    if (filteredVariation) {
      filteredVariation.categories = filteredVariation.categories || product.categories;
      filteredVariation.images = filteredVariation.images || product.images;
      filteredVariation.metaData = filteredVariation.metaData || product.metaData;
      filteredVariation.name = filteredVariation.name || product.name;
    }

    if (product.amount) {
      filteredVariation.amount = product.amount;
    }

    return filteredVariation;
  }

  getWorkload(blForceReload = false) {
    return new Promise((resolve, reject) => {
      // if (this.workload && !blForceReload) {
      //   resolve(this.workload);
      // } else {
        this.taxi.getSettings()
          .then((taxiSettings: taxiSettings) => {
            // console.log('> taxiSettings', taxiSettings);
            // this.workload = taxiSettings.workload;
            resolve(taxiSettings);
          })
          .catch(reject);
      // }
    });
  }

  parseDescriptionToProductItems(product: any) {
    let items = [];
    let p = $('<div>' + product.description.replace(/(?:\r\n|\r|\n)/g, '') + '</div>').find('p').eq(1);
    let html = p && p.length ? p.html() : null;
    let descriptionExplode = html ? html.split('<br>') : null;
    if (descriptionExplode) {
      descriptionExplode.forEach((string: string, index: number) => {
        let stringExplode = string.split('x ');
        if (stringExplode.hasOwnProperty(1)) {
          items.push({
            id: index,
            amount: parseInt(stringExplode[0]),
            name: stringExplode[1],
          });
        }
      });
    }
    return items;
  }

  parseProducts(products: any, options: any = {}) {
    return new Promise((resolve, reject) => {
      products = JSON.parse(JSON.stringify(products));

      let iWeekday: string;
      if (this._deliveryOptions && this._deliveryOptions.date) {
        iWeekday = '' + moment(this._deliveryOptions.date).weekday();
      } else {
        iWeekday = '' + moment().weekday();
      }

      if (products && products.length) {
        let iWithVariations = 0;
        products.forEach((product: any, index: any) => {
          if (products[index]) {
            products[index].metaData = products[index].metaData || {};

            if (iWeekday) {
              let weekdayMetaItemSelect = product.meta_data.filter((metaDataItem: any) => {
                return metaDataItem.key === 'broetchen.taxi:weekdays';
              });
              let weekdayMetaItem = weekdayMetaItemSelect[0] || null;
              if (weekdayMetaItem && weekdayMetaItem.value && weekdayMetaItem.value.length && product.manage_stock) {
                products[index].weekday = weekdayMetaItem.value;
                let weekdays = products[index].weekday;
                products[index].todayAvailable = weekdays.indexOf(iWeekday) !== -1;
              } else {
                products[index].todayAvailable = true;
              }
            } else
              if (!iWeekday) {
                products[index].todayAvailable = false;
              } else {
                products[index].todayAvailable = true;
              }

            new Promise((resolve, reject) => {
              if (product.variations && product.variations.length && (typeof product.variations[0] === 'number') && !options.withoutDeepParsing) {
                iWithVariations++;
                this.getProductVariations(product.id, (iWithVariations * 1000))
                  .then((variations: any) => {
                    let minPrice: number = null, maxPrice: number = null, price: number = null;
                    variations.forEach((variation: any) => {
                      price = parseFloat(variation.price);
                      maxPrice = maxPrice <= price ? price : maxPrice ? maxPrice : price;
                      minPrice = minPrice >= price ? price : minPrice ? minPrice : price;
                    });
                    product.variations = variations;
                    product.price = minPrice !== maxPrice ? minPrice + ' - ' + maxPrice : minPrice;
                    resolve(product);
                  })
                  .catch(error => {
                    console.warn('variations error:', error);
                    resolve(product);
                  });
              } else {
                resolve(product);
              }
            })
              .then((product: any) => {
                products[index] = product;

                if (!products.hasOwnProperty(index + 1)) {
                  resolve(products);
                }
              })
              .catch((error: any) => {
                if (!products.hasOwnProperty(index + 1)) {
                  resolve(products);
                }
              });
          }
        });
      }
    });
  }

  schedulePlannedOrder(basketStorage: any = null) {

    let Articles = [];

    basketStorage = basketStorage || this.basket.getBasket();
    basketStorage.forEach((Article: any) => {
      Articles.push(this.basket.toBasketItem(Article));
    });

    return this.AppCMS.loadPluginData(
      'woocommerceextend',
      {
        basket: Articles,
        basketInfo: this.basket.calculateBasketInfo(basketStorage),
        tmpOrder: this.ordersService.getTmpOrder(),
        user: this.userService.getUser(),
      },
      ['schedulePlannedOrder']
    );
  }

  useCoupon(coupon: any) {

    //amount
    //date_expires
    //description
    //excluded_product_categories
    //excluded_product_ids
    //exclude_sale_items
    //product_ids
    //usage_limit
    //usage_limit_per_user

    switch (coupon.discount_type) {
      case 'percent':
        return this.useCouponWithDiscountTypePercentage(coupon);
      default:
        console.warn('not supported discount type:', coupon.discount_type);
        break;
    }

  }

  useCouponWithDiscountTypePercentage(coupon: any) {
    return new Promise((resolve, reject) => {
      if (coupon.product_ids && coupon.product_ids.length) {
        this.useCouponWithDiscountTypePercentageWithProductIds(coupon).then(resolve).catch(reject);
      } else
        if (coupon.product_categories && coupon.product_categories.length) {
          this.useCouponWithDiscountTypePercentageWithProductCategories(coupon).then(resolve).catch(reject);
        } else {
          this.useCouponWithDiscountTypePercentageOnAll(coupon).then(resolve).catch(reject);
        }
    });
  }

  useCouponWithDiscountTypePercentageOnAll(coupon: any) {
    return new Promise((resolve, reject) => {
      let basketStorage = this.basket.getBasket();
      if (basketStorage && basketStorage.length) {
        basketStorage.forEach((basketItem: any) => {
          basketItem.price = 0;
        });
        this.basket.setBasket(basketStorage).then(resolve).catch(reject);
      }
    });
  }

  useCouponWithDiscountTypePercentageWithProductCategories(coupon: any) {
    return new Promise((resolve, reject) => {
      let basketStorage = this.basket.getBasket();
      if (basketStorage && basketStorage.length) {
        basketStorage.forEach((basketItem: any) => {
          if (basketItem.categories && basketItem.categories.length) {
            let productCategoryIds = [];
            basketItem.categories.forEach((category: any) => {
              productCategoryIds.push(category.id);
            });
            let blMatch = false;
            coupon.product_categories.forEach((couponCategory: number) => {
              blMatch = blMatch || productCategoryIds.includes(couponCategory);
            });
            if (blMatch) {
              basketItem.price = '0.00';
            }
          }
        });
        this.basket.setBasket(basketStorage).then(resolve).catch(reject);
      }
    });
  }

  useCouponWithDiscountTypePercentageWithProductIds(coupon: any) {
    return new Promise((resolve, reject) => {
      let products = [];
      coupon.product_ids.forEach((productId: any, index: number) => {
        this.getProductByUid(productId)
          .then((product: any) => {
            product.price = (parseFloat(product.price) / 100) * (100 - coupon.amount);
            products.push(product);

            this.basket.addCouponLine(coupon)
              .then(() => {
                this.basket.add(product)
                  .then(() => {
                    if (products.length === coupon.product_ids.length) {
                      resolve({
                        success: true,
                        products: products,
                      });
                    }
                  })
                  .catch(reject);
              })
              .catch(reject);
          })
          .catch(reject);
      });
    });
  }

}