import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'delivery-type',
    pathMatch: 'full'
  },
  {
    path: 'delivery-type',
    loadChildren: () => import('./delivery-type/delivery-type.module').then( m => m.DeliveryTypePageModule)
  },
  {
    path: 'category',
    loadChildren: './category/category.module#CategoryPageModule'
  },
  // {
  //   path: 'category/:categoryId',
  //   loadChildren: './category/category.module#CategoryPageModule'
  // },
  {
    path: 'details/:productId',
    loadChildren: './detail/detail.module#DetailPageModule'
  },
  {
    path: 'category/:branchid',
    // loadChildren: './category/category.module#CategoryPageModule',
    loadChildren: () => import('./category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'dynamic/:pageId',
    loadChildren: './dynamic/dynamic.module#DynamicPageModule'
  },
  {
    path: 'order/:orderId',
    loadChildren: './order/order.module#OrderPageModule'
  },
  {
    path: 'coupon/validate/:couponCode',
    loadChildren: './coupon-validate/coupon-validate.module#CouponValidatePageModule'
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'logininfo',
    loadChildren: () => import('./logininfo/logininfo.module').then( m => m.LogininfoPageModule)
  },
  {
    path: 'view-profile',
    loadChildren: () => import('./view-profile/view-profile.module').then( m => m.ViewProfilepageModule)
  },
  {
    path: 'update-profile',
    loadChildren: () => import('./update-profile/update-profile.module').then( m => m.UpdateProfilepageModule)
  },
  {
    path: 'view-profile-image',
    loadChildren: () => import('./view-profile-image/view-profile-image.module').then( m => m.ViewProfileImagePageModule)
  },
  {
    path: 'configure-product',
    loadChildren: () => import('./configure-product/configure-product.module').then( m => m.ConfigureProductPageModule)
  },
  {
    path: 'delivery-options',
    loadChildren: () => import('./delivery-options/delivery-options.module').then( m => m.DeliveryOptionsPageModule)
  },
  {
    path: 'delivery-options/:branchid',
    loadChildren: () => import('./delivery-options/delivery-options.module').then( m => m.DeliveryOptionsPageModule)
  },
  {
    path: 'deliveryarea',
    loadChildren: () => import('./deliveryarea/deliveryarea.module').then( m => m.DeliveryareaPageModule)
  },
  {
    path: 'deliveryareamodal',
    loadChildren: () => import('./deliveryareamodal/deliveryareamodal.module').then( m => m.DeliveryareamodalPageModule)
  },
  {
    path: 'on-delivery',
    loadChildren: () => import('./on-delivery/on-delivery.module').then( m => m.OnDeliveryPageModule)
  },
  {
    path: 'points',
    loadChildren: () => import('./points/points.module').then( m => m.PointsPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'closed',
    loadChildren: () => import('./closed/closed.module').then( m => m.ClosedPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'store-settings',
    loadChildren: () => import('./store-settings/store-settings.module').then( m => m.StoreSettingsPageModule)
  },
  {
    path: 'connect-with-store',
    loadChildren: () => import('./connect-with-store/connect-with-store.module').then( m => m.ConnectWithStorePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'coupon',
    loadChildren: () => import('./coupon/coupon.module').then( m => m.CouponPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'openings',
    loadChildren: () => import('./openings/openings.module').then( m => m.OpeningsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'basket',
    loadChildren: () => import('./basket/basket.module').then( m => m.BasketPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'planner',
    loadChildren: () => import('./planner/planner.module').then( m => m.PlannerPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    // canActivate: [AuthGuardService]
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'planner-create',
    loadChildren: () => import('./planner-create/planner-create.module').then( m => m.PlannerCreatePageModule)
  },
  {
    path: 'store/:storeLookupName',
    loadChildren: () => import('./store/store.module').then( m => m.StorePageModule)
  },
  {
    path: 'address',
    loadChildren: () => import('./address/address.module').then( m => m.AddressPageModule)
  },
  {
    path: 'modal',
    loadChildren: () => import('./register/modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'baskte-modal',
    loadChildren: () => import('./baskte-modal/baskte-modal.module').then( m => m.BaskteModalPageModule)
  },
  {
    path: 'suggestion',
    loadChildren: () => import('./suggestion/suggestion.module').then( m => m.SuggestionPageModule)
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./confirmation/confirmation.module').then( m => m.ConfirmationPageModule)
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
