import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { DatePipe } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppcmsService } from './appcms.service';
import { PagesService } from './pages.service';
import { UserService } from './user.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { PayPal } from '@ionic-native/paypal/ngx';
import { Network } from '@ionic-native/network/ngx';
import { ConfigureProductPageModule } from './configure-product/configure-product.module';
import { Device } from '@ionic-native/device/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { InAppReview } from "@ionic-native/in-app-review/ngx";
import { LaunchReview } from '@ionic-native/launch-review/ngx';
import { IonicRatingModule } from 'ionic4-rating';
// import { JoditAngularModule } from 'jodit-angular';
import { FeedbackPageModule } from './feedback/feedback.module';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Camera } from '@ionic-native/camera/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AuthGuardService } from './auth-guard.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { DeviceAccounts } from '@ionic-native/device-accounts/ngx';
import { ReactiveFormsModule } from '@angular/forms';
 import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, ReactiveFormsModule,
    HttpClientModule,
    IonicModule.forRoot({
      scrollAssist: false,
      scrollPadding: true,
      //mode: 'ios',
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    // JoditAngularModule,
    IonicRatingModule,
    ConfigureProductPageModule,
    FeedbackPageModule,
    
  ],
  providers: [
    AppcmsService,
    Camera,
    CallNumber,
    DatePicker,
    Deeplinks,
    Device,
    FileTransfer,
    Geolocation,
    InAppBrowser,
    InAppReview,
    LaunchReview,
    NativeGeocoder,
    Network,
    // OneSignal,
    PayPal,
    FCM,
    PagesService,
    StatusBar,
    SplashScreen,
    UserService,
    ScreenOrientation,
    AuthGuardService,
    Keyboard,
    AppVersion,
    DeviceAccounts,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
