import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class LogoService {

  locked: boolean = false;

  constructor(

  ) {
    
  }

  hide() {
    if(!this.isLocked()) {
      $('.ion-router-outlet-before').fadeOut();
    }
  }

  isLocked() {
    return this.locked;
  }

  lock() {
    this.locked = true;
  }

  show() {
    if(!this.isLocked()) {
      $('.ion-router-outlet-before').fadeIn();
    }
  }

  unlock() {
    this.locked = false;
  }

}
