import { Injectable } from "@angular/core";
import { InAppReview } from "@ionic-native/in-app-review/ngx";
import { AppcmsService } from "./appcms.service";
import { EventsService } from "./events.service";
import { AlertController, Platform } from "@ionic/angular";
import { CacheService } from "./cache.service";
import { LaunchReview } from '@ionic-native/launch-review/ngx';

@Injectable({
  providedIn: "root",
})
export class ReviewService {

  constructor(
    public alertCtrl: AlertController,
    public AppCMS: AppcmsService,
    public cache: CacheService,
    public events: EventsService,
    public InAppReview: InAppReview,
    public launchReview: LaunchReview,
    public platform: Platform,
  ) {
    
  }

  onStarClick(iRating: number) {
    if (iRating >= 4) {
      return this.showExternal();
    } else {
      return this.thankYouMessage();
    }
  }

  performExternal() {
    this.InAppReview.requestReview()
      .then((res: any) => {
        //console.log("res", res);
        return this.thankYouMessage();
      })
      .catch((error: any) => {
        //console.log('in app review error', error);     
        let appId = this.platform.is('android') ? 'taxi.broetchen.app' : 'taxi.broetchen.demo';
        
        if(this.launchReview.isRatingSupported()){
          this.launchReview.rating().subscribe(() => console.log('Successfully launched rating dialog'));
        } else {
          this.launchReview.launch(appId).then(() => console.log('Successfully launched store app'))
          .catch(() => {
            this.events.publish("error", error);
          });
        }
      });
  }

  async showIfShould() {
    let blPreventReviewModal: cacheItem = this.cache.get(
      'blPreventReviewModal',
      60 * 60 * 24 * 365
    );
    if (!blPreventReviewModal) {
      this.cache.set('blPreventReviewModal', true);
      return this.showInternal();
    }
  }

  async showExternal() {
    let alert = await this.alertCtrl.create({
      header: "Im App Store bewerten?",
      subHeader: "Wir würden uns über eine Bewertung im App Store freuen",
      buttons: [
        {
          text: "OK",
          handler: async () => {
            await alert.dismiss();
            return this.performExternal();
          },
        },
        {
          text: "Nein",
          role: "cancel",
          handler: async () => {
            await alert.dismiss();
            return this.thankYouMessage();
          },
        },
      ],
    });
    await alert.present();
  }

  async showInternal() {
    let alert = await this.alertCtrl.create({
      header: "App bewerten",
      subHeader: "Wie zufrieden sind Sie mit dem broetchen.taxi?",
      cssClass: "reviewInternalAlert",
      inputs: [
        {
          type: "radio",
          name: "rating",
          value: 1,
          handler: async (data: any) => {
            await alert.dismiss();
            return this.onStarClick(data.value);
          },
        },
        {
          type: "radio",
          name: "rating",
          value: 2,
          handler: async (data: any) => {
            await alert.dismiss();
            return this.onStarClick(data.value);
          },
        },
        {
          type: "radio",
          name: "rating",
          value: 3,
          handler: async (data: any) => {
            await alert.dismiss();
            return this.onStarClick(data.value);
          },
        },
        {
          type: "radio",
          name: "rating",
          value: 4,
          handler: async (data: any) => {
            await alert.dismiss();
            return this.onStarClick(data.value);
          },
        },
        {
          type: "radio",
          name: "rating",
          value: 5,
          handler: async (data: any) => {
            await alert.dismiss();
            return this.onStarClick(data.value);
          },
        },
      ],
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
        },
      ],
    });
    await alert.present();
  }
  
  thankYouMessage() {
    setTimeout(() => {
      this.events.publish("alert", {
        header: "Vielen Dank!",
        message: "Danke für Ihr Feedback!",
      });
    }, 800);
  }

}