import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { UserService } from './user.service';
import { EventsService } from './events.service';
import { WoocommerceextendService } from './woocommerceextend.service';
import { CacheService } from './cache.service';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  default: any = window.localStorage.getItem('current_store') ? JSON.parse(window.localStorage.getItem('current_store')) : null;

  PERSISTENCE_KEY_STORE = "current_store";

  stores: any = {};

  // for store sharing between pages
  tmp: any;

  constructor(
    public AppCMS: AppcmsService,
    public cache: CacheService,
    public events: EventsService,
    public userProvider: UserService,
    public woocommerce: WoocommerceextendService,
    public themeService: ThemeService,
  ) {
   
  }

  create(store: store = {}) {
    return this.AppCMS.loadPluginData('Company', store);
  }
  companyorderpdfemail(store: FormData) {
    return this.AppCMS.loadPluginData('Supplier/supplierorderInvoicemail', store);
  }
  companyorderemail(store: store = {}) {
    return this.AppCMS.loadPluginData('Company/companyorderemail', store);
  }
  suggestionemail(store: store = {}) {
    return this.AppCMS.loadPluginData('Member/suggestionemail', store);
  }
  updatemembercompany(store: store = {}) {
    return this.AppCMS.putPluginData('Member/updatemembercompany', store);
  }
  
  getAll(blForceRefresh: boolean = false, params: any = {}) {
    //console.log(blForceRefresh, params)
    return new Promise((resolve, reject) => {
      let apiUrl = params.apiUrl || this.AppCMS.getApiUrl();
      let cacheKey = 'stores_' + apiUrl;
      let fromCache: cacheItem = this.cache.get(cacheKey, (60 * 60 * 24));
      let orderData = JSON.parse(window.localStorage.getItem('orderData'));
      
      if(fromCache && fromCache.data) {
        this.stores[apiUrl] = fromCache.data;
      }

      if(!blForceRefresh && this.stores[apiUrl]) {
        resolve(this.stores[apiUrl]);
      } else {
        this.AppCMS.loadUrl('Branch/allbranches/'+orderData.deliveryType+ '/' +(this.themeService.tenantId?this.themeService.tenantId:''))
        .then((stores: any) => {
        //  console.log('============stores==========='+stores)
          // if(stores && stores.length) {
          //   stores.forEach((store: store) => {

              // if(store.hasOwnProperty('photo') && typeof store.photo === 'string') {
              //   store.photo = store.photo.replace('http://', 'https://');
              // }

              /*
              let _methods = [];
              console.log('store.classifications', store.classifications);

              if(store.classifications && store.classifications.apiUrl) {
                this.woocommerce.getShippingZoneMethods(1, store.classifications.apiUrl)
                .then((methods: any) => {
                  methods.forEach((method: any) => {
                    _methods.push(method.method_id);
                  });
                  store.classifications.methods = _methods;
                })
                .catch(reject);
              }
              */
             
          //   });
          // }
          
          this.stores[apiUrl] = stores;
          this.cache.set(cacheKey, stores);

          resolve(stores);
        })
        .catch(reject);
      }
    });
  }

  getAllCompany(blForceRefresh: boolean = false, params: any = {}) {
    //console.log(blForceRefresh, params)
    return new Promise((resolve, reject) => {
      let apiUrl = params.apiUrl || this.AppCMS.getApiUrl();
      let cacheKey = 'stores_' + apiUrl;
      let fromCache: cacheItem = this.cache.get(cacheKey, (60 * 60 * 24));
      
      if(fromCache && fromCache.data) {
        this.stores[apiUrl] = fromCache.data;
      }

      if(!blForceRefresh && this.stores[apiUrl]) {
        resolve(this.stores[apiUrl]);
      } else {
        this.AppCMS.loadUrl('Company')
        .then((stores: any) => {
                
          this.stores[apiUrl] = stores;
          this.cache.set(cacheKey, stores);

          resolve(stores);
        })
        .catch(reject);
      }
    });
  }


  getByProfile(profile: any) {
    return this.getCurrent(profile);
  }

  getByUid(uid: number) {
    //console.log('get store by uid:', uid);
    return new Promise((resolve, reject) => {

      let allStores = this.stores[this.AppCMS.getMainApiUrl()];
      //console.log('> allStores', allStores);

      this.AppCMS.loadPluginData('stores', {})
      .then((json: any) => {
        if(typeof json != 'object'){
          reject("Ein Systemfehler ist aufgetreten! Versuche es später erneut.");
        } else
        if (json.status == false || json.success == false) {
          reject("Keine Geräteinformationen vorhanden!");
        } else {
          resolve(json);
        }
      })
      .catch(reject);
    });
  }

  getBrancheByqrcode(branchid: any) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadUrl('Branch/QrCodebranche/'+branchid)
        .then((response: any) => {
          if (response.success) {
            resolve(response);
          } else {
            resolve(response || 'Ein unbekannter Fehler ist aufgetreten');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  validateQR(qrcode:any) {
    return new Promise((resolve, reject) => {
      this.AppCMS.postPluginData('Qrcode/validatQRCode',qrcode)
        .then((response: any) => {
          if (response.success) {
            resolve(response);
          } else {
            resolve(response || 'Ein unbekannter Fehler ist aufgetreten');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  getCurrent(user = null) {
    user = user || this.userProvider.getUser();
    return user && user.classifications && user.classifications.stores ?
      user.classifications.stores :
      null;
  }

  getDefault() {
    return this.default;
  }
  
  public getFromStore(blUserStore: boolean = false) : Promise<any> {
    return new Promise((resolve, reject) => {
      let user = this.userProvider.getUser() || {};
      let fromStorage = JSON.parse(localStorage.getItem(this.PERSISTENCE_KEY_STORE));
      resolve(
        user.classifications && blUserStore ? user.classifications.stores || (fromStorage || []) : (fromStorage || []),
      );
    });
  }

  getTmp() {
    return this.tmp;
  }

  find(input: string) {
    input = (input || '').toLowerCase();
    
    return new Promise((resolve, reject) => {
      if(!input || !input.length) {
        resolve([]);
      } else {
        this.getAllCompany(true)
        .then((stores: store[]) => {
          let matching = stores.filter((store: store) => {
            return (store && store.registrationnumber ? store.registrationnumber || '' : '').toLowerCase().indexOf(input) !== -1;
          });
          resolve(matching);
        })
        .catch(reject);
      }
    });
  }

  setDefault(store: store) {
    this.default = store;
    // window.localStorage.setItem('defaultStore', JSON.stringify(this.default));
    window.localStorage.setItem(this.PERSISTENCE_KEY_STORE, JSON.stringify(this.default));

  }

  public setInStorage (store: store, remove: boolean = false, update: boolean = false) : Promise<any> {
    return new Promise((resolve, reject) => {
      this.events.publish('store:changed', store);
      if(remove) {
        // localStorage.removeItem(this.PERSISTENCE_KEY_STORE);
        this.events.publish('store:removed', store);
        this.getFromStore().then(resolve).catch(reject);
      } else {
        this.events.publish('store:updated', store);
        if(update) {
          localStorage.setItem(this.PERSISTENCE_KEY_STORE, JSON.stringify(store));
          this.events.publish('store:synced', store); 
          this.update(store).then(resolve).catch(reject);
        } else {
          localStorage.setItem(this.PERSISTENCE_KEY_STORE, JSON.stringify(store));
          this.getFromStore().then(resolve).catch(reject);
        }
      }
    });
  }

  setTmp(tmp: store) {
    this.tmp = tmp;
  }

  update(store: store) {
    return this.AppCMS.loadPluginData('stores', store);
  }

}
