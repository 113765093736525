import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(
    public cache: CacheService,
  ) { }

  count() {
    let statistics = this.get();
    statistics.countLoaded = statistics.countLoaded || 0;
    statistics.countLoaded++;
    
    this.cache.set('statistics', JSON.stringify(statistics));
    return statistics;
  }

  get() {
    let age = 60 * 60 * 24 * 365,
        statistics: cacheItem = this.cache.get('statistics', age);
    return (statistics ? (statistics.data || {}) : {});
  }

}
