import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { CacheService } from './cache.service';
import { EventsService } from './events.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  endpoint = 'https://broetchen-taxi-dev-api.azurewebsites.net/api/Member/login';

  profileImagEndpoint = "https://broetchen-taxi-dev-api.azurewebsites.net/api/SystemBranding/uploadimage"

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  addressList: any[] = window.localStorage.getItem('addressList') ? JSON.parse(window.localStorage.getItem('addressList')) : [];

  afterLoginRedirectUrl: string;

  avatars = [
    {
      src: './assets/img/avatars/1.jpg'
    },
    {
      src: './assets/img/avatars/2.jpg'
    },
    {
      src: './assets/img/avatars/3.jpg'
    },
    {
      src: './assets/img/avatars/4.jpg'
    },
    {
      src: './assets/img/avatars/5.jpg'
    },
    {
      src: './assets/img/avatars/6.jpg'
    },
    {
      src: './assets/img/avatars/7.jpg'
    },
  ];

  billingInformation: any = {};

  deliveryKeys: any = window.localStorage.getItem('deliveryKeys') ? JSON.parse(window.localStorage.getItem('deliveryKeys')) : {};

  registerRedirectUri: string = '/home';

  user = window.localStorage.getItem('appcmsUser') ? JSON.parse(window.localStorage.getItem('appcmsUser')) : {};

  constructor(
    public AppCMS: AppcmsService,
    public cache: CacheService,
    public events: EventsService,
    private httpClient: HttpClient
  ) { }

  createUser(user: any): Observable<any> {
    return this.httpClient.post<any>(this.endpoint, JSON.stringify(user), this.httpOptions)
      .pipe(
        catchError(this.handleError<any>('Error occured'))
      );
  }

  get(): Observable<any> {
    return this.httpClient.get<any>('https://broetchen-taxi-dev-api.azurewebsites.net/api/Calendar/1', this.httpOptions)
      .pipe(
        catchError(this.handleError<any>('Error occued'))
      )
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      //console.error('======================123'+JSON.stringify(error));
      //console.log('======================456'+`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  addAddress(address: any) {
    if (!this.inAddressList(address)) {
      this.addressList.push(address);
      window.localStorage.setItem('addressList', JSON.stringify(this.addressList));
    }
  }

  addFriend(profileId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          user: this.getUser().uid
        },
        ['add']
      )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getAddressList() {
    return this.addressList;
  }

  getAfterLoginRedirectUrl() {
    return this.afterLoginRedirectUrl;
  }

  getApiCredentials() {
    return new Promise((resolve, reject) => {
      let user = this.getUser() || {};
      return {
        'email': user.email,
        'password': user.password,
      };
    });
  }

  getAvatars() {
    return this.avatars;
  }

  getBillingInformation(data: any = {}) {
    let user = this.getUser() || {};
    if(!user.firstName) {
      user.firstName = this.getDeliveryKey('first_name') || '';
    }

    if (!user.lastName) {
      user.lastName = this.getDeliveryKey('last_name') || '';
    }

    if (!user.city) {
      user.city = this.getDeliveryKey('city') || '';
    }

    if (!user.plz) {
      user.plz = this.billingInformation.plz || (this.getDeliveryKey('postcode') || '');
    }


    let _user = Object.assign({
      first_name: user.firstName || '-',
      last_name: user.lastName || '-',
      address_1: this.billingInformation.address_1 || (user.street + ' ' + user.streetNo),
      address_2: this.billingInformation.address_2 || '',
      city: this.billingInformation.city || (user.city || 'Bocholt'),
      postcode: user.plz || '',
      country: user.country || 'DE',
      email: user.email || '',
      phone: user.phone || '',
      password: user.password || '',
    }, data);

    //delete _user.password;
    return _user;
  }

  getByClassification(Key, Value, forceReload = false) {
    return this.AppCMS.loadPluginData('classification', {}, ['customers', Key, Value]);
  };

  getByUid(uid: any) {
    return this.AppCMS.loadPluginData('user', {}, [uid]);
  };

  getDeliveryKey(key: string) {
    return this.deliveryKeys[key] || null;
  }

  getPlz() {
    return this.getDeliveryKey('postcode') || (this.getUser() ? this.getUser().plz : null);
  }

  getRegisterRedirectUri() {
    return this.registerRedirectUri;
  }

  getUid() {
    let user = this.getUser() || {};
    return user.uid;
  }

  validateCoupon(couponData: any) {
    console.log('couponData', couponData);
    return new Promise((resolve, reject) => {
      this.AppCMS.postPluginData('Coupon/validatCoupon', couponData)
        .then((response: any) => {
          if (response.success) {
            //response.user.password = userData.password;
            resolve(response);
          } else {
            resolve(response || 'Ein unbekannter Fehler ist aufgetreten');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  
  getUser(withPassword = false) {
    let user: any = Object.assign({}, this.user);

    user.firstName = user.firstName || this.getDeliveryKey('first_name');
    user.lastName = user.lastName || this.getDeliveryKey('last_name');
    user.phone = user.phone || this.getDeliveryKey('phone');
    
    // if(!withPassword) {
    //   delete user.password;
    // }

    if (user.hasOwnProperty('classifications')) {
      user.classifications = JSON.parse(JSON.stringify(user.classifications || {}));
      let blEmptyClassificationsArray = typeof user.classifications !== 'undefined' && user.classifications.length === 0;
      if (blEmptyClassificationsArray) {
        user.classifications = {
          type: 'Kunde',
        };
      }
    }

    return user;
  }

  isFriend(profileId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          userId: this.getUser().uid
        },
        ['isFriend']
      )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  inAddressList(address: any) {
    if (this.addressList && this.addressList.length) {
      let blMatch = this.addressList.filter((_address: any) => {
        return _address.route === address.route &&
          _address.street_number === address.street_number &&
          _address.postal_code === address.postal_code;
      });
      return !!blMatch.length;
    }
    return false;
  }

  login(userData: any) {
    console.log('login', userData);
    return new Promise((resolve, reject) => {
      this.AppCMS.postPluginData('Member/login', userData)
        .then((response: any) => {
          if (response.success) {
            console.log(response,"response.succes")
            window.localStorage.setItem('jwtToken',response?.user.token)
            // this.jwtService.saveToken(response.token);
            //response.user.password = userData.password;
            this.setUser(response.user);      // set local storage
            resolve(response);
          } else {
            reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.setUser({})
        .then(() => {
          window.localStorage.setItem('orderData', JSON.stringify({}));     // orderData
          let keysToRemove = ["qrcodedata","qrcodeid","QrcodeBranchId","isQrcodeScanned","notes", "basket", "current_store", "orderData", "companyemail", "firmName", "Coupondescription", "IsCouponapplied", "Coupondiscount", "miniumorderamount", "couponresponse", "paymentMethod"];
    
          keysToRemove.forEach(k =>
            window.localStorage.removeItem(k))
          this.events.publish('appcms:user:loggedout');
          resolve(1);
        })
        .catch(reject);
    });
  }

  register(userData: any) {
    return new Promise((resolve, reject) => {
      userData.active = true;
      userData.public = true;

      this.AppCMS.postPluginData('Member', userData)
        .then((response: any) => {
          if (response.success) {
            this.setUser(response.user);      // set local storage
            resolve(response);
          } else {
            reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public validateEmail(email: string) {
    return new Promise((resolve, reject) => {
      this.AppCMS.postPluginData('User/validatemailid', { email: email })
        .then((response: any) => {
          if (response.success) {
            this.setUser(response.user);      // set local storage
            resolve(response);
          } else {
            reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  removeFriend(profileId: any) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          user: this.getUser().uid
        },
        ['remove']
      )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  forgotPassword(data: any) {
    return this.AppCMS.loadPluginData('Member/requestNewPassword', data);
  }

  requestNewPassword(data: any) {
    return this.AppCMS.loadPluginData('Member/changePassword', data);
  }

  setAfterLoginRedirectUrl(url: string) {
    this.afterLoginRedirectUrl = url;
  }

  setAfterRegisterRedirectUrl(registerRedirectUri: string) {
    this.registerRedirectUri = registerRedirectUri;
  }

  setBillingInformation(key: string, value: any) {
    this.billingInformation[key] = value;
  }

  setDeliveryKey(key: string, value: string) {
    this.deliveryKeys[key] = value;
    window.localStorage.setItem('deliveryKeys', JSON.stringify(this.deliveryKeys));
  }

  setStreet(street: string) {
    this.user.street = street;
    this.setUser(this.user);
  }

  setStreetNo(streetNo: string) {
    this.user.streetNo = streetNo;
    this.setUser(this.user);
  }

  setUser(user: any, blSync: boolean = false) {
    return new Promise((resolve, reject) => {
      console.log('user', user)
      this.user = user;
      window.localStorage.setItem('appcmsUser', JSON.stringify(user));

      if (blSync) {
        this.syncUser()
          .then(() => {
            resolve(this.getUser());
          })
          .catch(reject);
      } else {
        resolve(this.getUser());
      }
    });
  }

  syncUser() {
    if (this.user.uid) {
      return this.update(this.user);
    }
  }

  update(user: any) {
    user.company = null;
    console.log('user', user)
    if(user.hasOwnProperty('classifications')) {
      user.classifications = JSON.parse(JSON.stringify(user.classifications));
    }
    return this.AppCMS.putPluginData('Member', user);  
  }

  getOrderData() {
    console.log(window.localStorage.getItem('orderData'),"window.localStorage.getItem('orderData')")
    return window.localStorage.getItem('orderData');
  }

  checkVersion(body: any) {
    return new Promise((resolve, reject) => {
      this.AppCMS.postPluginData('Version/CheckUpdate', body)
        .then((response: any) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  updatedevicetoken(devicedata) {
    console.log('update device', devicedata)
    return this.AppCMS.putPluginData('RegisteredDevicesGoogle', devicedata)
  }
  adddevicetoken(devicedata) {
    console.log('Add device', devicedata)
    return this.AppCMS.postFormData('RegisteredDevicesGoogle', devicedata)
    .pipe(map((data) => {  
      console.log('CHeck API working', data)
       return data;
     },
     err => { 
       return err;
     }
   ));
    
  }
  uploadProfile(dataUpload) {
    
    return this.AppCMS.postFormData('Member/uploadimage', dataUpload)
    .pipe(map((data) => {  
       return data; 
     },
     err => { 
       return err;
     }
   )); 
    
  }
}