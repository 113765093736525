import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { UserService } from './user.service';
import { PaymentsService } from './payments.service';

import * as moment from 'moment';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  basketStorage: any = window.localStorage.getItem('basket') ? JSON.parse(window.localStorage.getItem('basket')) : [];
  categoryStorage: any = window.localStorage.getItem('woocommerceCategories_') ? JSON.parse(window.localStorage.getItem('woocommerceCategories_')) : [];

  couponLines: any = null;

  editItemIndex: number;

  // requestMinimumValue: number = 8.50;
  requestMinimumValue: number = 0;

  shippingLines: any;
  
  suggestedCategories: any[] = [
    {id: 64, description: "Wie wäre es mit Kakao, O-Saft, Milch?", name: "Getränke", slug: "getraenke"},
    {id: 69, description: "Möchtest du noch eine Zeitung dazu bestellen?", name: "Zeitungen", slug: "zeitungen"}
  ];

  constructor(
    public AppCMS: AppcmsService,
    public cache: CacheService,
    public payments: PaymentsService,
    public userService: UserService,
  ) {

  }

  add(item: product) {
    return new Promise((resolve, reject) => {
      
      let storageSimilarItemSearch = this.basketStorage.filter((_item: product) => {
        return item.uid === _item.uid;
      }), storageSimilarItem = storageSimilarItemSearch[0] || null;
        //console.log(item)
      if(storageSimilarItem) {
        storageSimilarItem.amount++;
        item.amount = parseFloat(storageSimilarItem.amount);
      } else {
        item.amount = parseFloat((item.amount || 1) + '');
        this.basketStorage.push(this.toBasketStorageItem(item));
      }
  
      this.setBasket(this.basketStorage).then(resolve).catch(reject);
    });
  }

  addCouponLine(couponLine: any) {
    return new Promise((resolve, reject) => {
      this.couponLines = this.couponLines || [];

      let inCouponLines = this.couponLines.filter((couponLine: any) => {
        return couponLine.code == couponLine.code;
      });
  
      if(inCouponLines && inCouponLines.length) {
        reject('Der Gutschein-Code ist bereits genutzt');
      } else {
        this.couponLines.push({
          code: couponLine.code,
        });
        resolve(this.couponLines);
      }
    });
  }

  calculateBasketInfo(basketStorage = null) {
    let orderData = JSON.parse(window.localStorage.getItem('orderData'));
    if(orderData?.deliveryType == 'Lieferung'){
      let minimumValue = window.localStorage.getItem('miniumorderamount') ? window.localStorage.getItem('miniumorderamount') : "0.0";
      let replace = minimumValue.replace(",", ".");
      this.requestMinimumValue = parseFloat(replace);
    }
    else{
      this.requestMinimumValue = 0;
    }
    let shippingLines = this.getShippingLines() || [];
    let basketInfo: basketInfo = {
      items: 0,
      price_a: (0).toFixed(2),
      hasRequestMinimumValue: false,
      coupon_lines: this.getCouponLines() || null,
    };

    let shippingLine = shippingLines[0] || null;
    let shippingMethodId = shippingLine ? shippingLine.method_id : null;

    if(shippingMethodId !== 'local_pickup') {
      basketInfo.requestMinimumValue = this.requestMinimumValue.toFixed(2);
    }

    basketStorage = basketStorage || this.basketStorage;
    basketStorage.forEach((basketItem: basketItem) => {
      if(basketItem.price) {

        if(typeof basketItem.price === 'string') {
          basketItem.price = parseFloat(basketItem.price);
        }

        let amount = parseInt((basketItem.amount || 1) + '');
        basketInfo.price_sub = (parseFloat(basketInfo.price_a) + (amount * basketItem.price)).toFixed(2);
        basketInfo.price_a = (parseFloat(basketInfo.price_a) + (amount * basketItem.price)).toFixed(2);
        basketInfo.items += basketItem.amount || 1;
      }
    });
    let customerNote = '';
    let datetime = this.userService.getDeliveryKey('datetime');
    let date = datetime && datetime !== 'sofort' ? moment(datetime, 'DD.MM.YYYY HH:mm') : moment();

    if(datetime) {
      customerNote = date.format('HH:mm');
    } else {
      customerNote = 'sofort';
    }

    if(this.userService.getDeliveryKey('customer_note')) {
      customerNote += ' - ' + this.userService.getDeliveryKey('customer_note');
      //console.log(basketInfo.requestMinimumValue+'===========')
    }
    
    if(basketInfo.items) {

      if(basketInfo.requestMinimumValue) {
        
        basketInfo.hasRequestMinimumValue = parseFloat(basketInfo.price_a) >= parseFloat(basketInfo.requestMinimumValue);
        
        if(!basketInfo.hasRequestMinimumValue) {
          basketInfo.difference = (parseFloat(basketInfo.requestMinimumValue) - parseFloat(basketInfo.price_a)).toFixed(2);
          basketInfo.price_a = basketInfo.requestMinimumValue;
        }
        
        basketInfo.requestMinimumValue = (parseFloat(basketInfo.requestMinimumValue)).toFixed(2);
      }

      basketInfo.price_a = (parseFloat(basketInfo.price_a)).toFixed(2);
      
      let metaData: any = [
        {
          key: '_orddd_lite_timestamp',
          value: date.format('DD MMMM, YYYY'),
        }
      ];

      basketInfo.meta_data = metaData;
    }
    
    basketInfo.customer_note = customerNote;
    basketInfo.billing_address = this.userService.getBillingInformation();

    shippingLines.total = basketInfo.difference || '0';
    basketInfo.shipping_lines = shippingLines;

    if(shippingMethodId === 'local_pickup') {
      basketInfo.billing_address.address_1 = basketInfo.billing_address.address_1 || shippingLine;
    }

    basketInfo.payment_details = {
      'method_id': this.payments.getPaymentMethodCode(),
      'method_title': this.payments.getPaymentMethodTitle(),
      'paid': this.payments.paid(),
    };
    return basketInfo;
  }
  
  clear() {
    return this.setBasket([]);
  }
  
  getBasket() {
    this.basketStorage = window.localStorage.getItem('basket') ? JSON.parse(window.localStorage.getItem('basket')) : [];
  
    // if(this.basketStorage) {
    //   this.basketStorage.forEach((basketItem: any, index: number) => {
    //     //this.basketStorage[index].meta_data = this.parseItemMetaData(basketItem);
    //   });
    // }
    return this.basketStorage;
  }

  getCategory() {
    if(this.categoryStorage) {
      this.categoryStorage.forEach((categoryItem: any, index: number) => {
        //this.basketStorage[index].meta_data = this.parseItemMetaData(basketItem);
      });
    }
    return this.categoryStorage;
  }

  getCouponLines() {
    return this.couponLines;
  }

  getEditItemIndex() {
    return this.editItemIndex;
  }
  
  getShippingLines() {
    return this.shippingLines;
  }

  parseBasketItemMetaData(metaData: any) {
    if(metaData) {
      metaData.forEach((metaDataItem: any) => {
        delete metaDataItem.id;
      });
  
      metaData = metaData.filter((metaDataItem: any) => {
        return metaDataItem.value && (metaDataItem.key.indexOf('_et_') === -1);
      });
  
      return metaData;
    }
  }

  parseItemMetaData(item: any) {
    item.meta_data = item.meta_data || [];
    if(item.metaData) {
      Object.keys(item.metaData).forEach((key: string) => {
        let value = item.metaData[key];
        let iInMetaData = null;
        let blInMetaDataSelect = item.meta_data.filter((metaDataItem: any, index: number) => {
          if(metaDataItem.key == key && value !== '') {
            iInMetaData = index;
            return true;
          }
        });
        let blInMetaData = blInMetaDataSelect ? !!blInMetaDataSelect[0] : false;
  
        if(key === 'cutted') {
          value = value ? 'Geschnitten' : 'Nicht geschnitten';
        }

        let metaItemEntry = {
          'key': key,
          'value': value,
        };

        if(!blInMetaData && value !== '') {
          item.meta_data.push(metaItemEntry);
        } else
        if(iInMetaData) {
          item.meta_data[iInMetaData] = metaItemEntry;
        }
        
      });
    }
    return item.meta_data;
  }

  removeFromBasket(product: any) {
    this.basketStorage = this.basketStorage.filter((basketItem: any, index: number) => {
      if(basketItem.id === product.id) {
        if(this.basketStorage[index].amount > 1) {
          this.basketStorage[index].amount--;
          product.amount = this.basketStorage[index].amount;
        } else {
          product.amount = 0;
          return false;
        }
      }
      return true;
    });
    return this.setBasket(this.basketStorage);
  }

  setBasket(basket: any) {
    return new Promise((resolve, reject) => {
      this.basketStorage = basket;
      window.localStorage.setItem('basket', JSON.stringify(this.basketStorage));
      resolve(this.basketStorage);
    });
  }

  setEditItemIndex(index: number) {
    this.editItemIndex = index;
  }

  setShippingLines(shippingLines: any) {
    this.shippingLines = shippingLines;
  }

  toBasketItem(item: any) {
    let metaData: any;

    //if(item.type === 'simple') {
      metaData = this.parseBasketItemMetaData(item.meta_data);
    //}

    let _item = {
      id: item.id,
      amount: parseFloat(item.amount || 1),
      name: item.name,
      meta_data: metaData,
      price: item.price,
      sale_price: item.sale_price,
      metaData: item.metaData,
    };

    return _item;
  }

  toBasketStorageItem(item: any) {
    //console.log(item, item.price);
    if(item.metaData) {
      item.meta_data = this.parseItemMetaData(item);
    }

    // let basketItem: basketItem = {
    //   amount: parseFloat(item.amount || 1),
    //   categories: item.categories,
    //   attributes: item.attributes,
    //   description: item.description,
    //   delivery_time: item.delivery_time,
    //   id: item.id,
    //   images: item.images,
    //   items: item.items,
    //   meta_data: item.meta_data,
    //   metaData: item.metaData,
    //   name: item.name,
    //   permalink: item.permalink,
    //   price: item.price,
    //   related_ids: item.related_ids,
    //   sale_price: item.sale_price,
    //   short_description: item.short_description,
    //   stock_status: item.stock_status,
    //   stock_quantity: item.stock_quantity,
    //   type: item.type,
    //   variations: item.variations,
    // };
    let itemPrice:any = item.price;
    // alert(itemPrice)
    let replacePrice = itemPrice.replace(",", ".");
    let basketItem: basketItem = {
      amount: parseFloat(item.amount || 1),
      categories: item.categoryId,
      description: item.description,
      delivery_time: item.delivery_time,
      uid: item.uid,
      image: item.image,
      vat: item.vat,
      name: item.name,
      price: replacePrice,
      isbundle: item.isbundle,
      bundleproductlist: item.bundleproductlist
    };

    //console.log(basketItem)

    if(item.type === 'variable') {
      basketItem.parent_id = item.basketItem;
    }

    return basketItem;
  }

  update(oldProduct: any, newProduct: any) {
    //console.log('update', oldProduct, newProduct);
    this.getBasket().forEach((basketItem: any) => {
      //console.log('match?', JSON.stringify(basketItem) === JSON.stringify(oldProduct));
    });
  }

  updateIndex(index: number, item: any) {
    let basket = this.getBasket();
    basket[index] = item;
    this.setBasket(basket);
  }
  calculateDiscount(couponresponse : any) {
    var Coupondiscount:number=0;
    if (couponresponse.success) {
    
      let basketInfo =this.calculateBasketInfo();
      var price:number=+basketInfo.price_a;
      if(price>=(+couponresponse.data.minimumvalue)){
        if(couponresponse.data.isfixed)
        {
          var Offerammount: number = couponresponse.data.offerammount==""? 0: +couponresponse.data.offerammount;
          if(Offerammount>=0)
          Coupondiscount= Offerammount;
        }
        else{
          var Offerammount: number = couponresponse.data.offerammount==""? 0: +couponresponse.data.offerammount;
          if(Offerammount>=0)
          Coupondiscount=price*(Offerammount/100);
        }
       }
     
      window.localStorage.setItem('coupon', couponresponse.data.couponcode);
      window.localStorage.setItem('Coupondiscount',Coupondiscount.toString());
      return Coupondiscount
    }
  }
}