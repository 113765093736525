import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppcmsService {

  apiUrl: string;

  // this will store the original api url, if changed programatically
  _apiUrl: string;

  language: string = 'de';

  // mainApiUrl: string = 'https://app.broetchen.taxi/api';
  // mainApiUrl:string = 'https://localhost:5001/api/';
  mainApiUrl:string = environment.apiURL;

  user = window.localStorage.getItem('appcmsUser') ? JSON.parse(window.localStorage.getItem('appcmsUser')) : null;

  constructor(
    public http: HttpClient
  ) {
    this.apiUrl = this.getApiUrl() || this.getMainApiUrl();
  }

  getApiUrl() {
    return this.apiUrl || this.getMainApiUrl();
  }

  getMainApiUrl() {
    return this.mainApiUrl;
  }

  getLanguage() {
    return this.language;
  }

  isMainApi() {
    return this.getApiUrl() === this.getMainApiUrl();
  }

  loadPluginData(plugin: string, data: any = {}, actions: any = undefined, params: any = {}) {
    return new Promise((resolve, reject) => {
      try {
        // let action = actions ? typeof actions === 'string' ? actions : actions.join('/') : null, url;
        // if(action) {
        //   url = (params.apiUrl || this.getApiUrl()) + '/' + plugin + '/' + action + '.json';
        // } else {
        //   url = (params.apiUrl || this.getApiUrl())  + '/' + plugin + '.json';
        // }
        // const token = this.jwtService.getToken();   
        // if (token) {
        //   headersConfig['Authorization'] = 'Bearer '+`${token}`;
        // }
        let headers = new Headers({ 'Content-Type': 'application/json'});
        // let headers = new Headers({ 'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, DELETE',
        // 'Access-Control-Allow-Headers': 'Accept,Accept-Language,Content-Language,Content-Type',
        // 'Access-Control-Expose-Headers': 'Content-Length,Content-Range' });
        this.http.post(this.mainApiUrl+plugin, data, { headers: headers as any })
        .subscribe(
          (response: any) => {
            console.log('> response', response);
            if(!response) {
              reject('Ein unbekannter Fehler ist aufgetreten');
            } else
            if(response.success == false && !response.message) {
              resolve(response);
            } else if(response.success == true) {
              resolve(response);
            } else if(response.success == false && response.message) {
              resolve(response);
            }
            else {
              reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
            }
          },
          (error: any) => {
            let message = error.message;
            if(message.indexOf('Http failure') === 0 || message.indexOf('http failure') === 0) {
              message = 'Wir arbeiten aktuell an unserem Lieferdienst und sind in Kürze wieder da. Bitte versuche es in Kürze erneut.';
            }
            console.warn('error', error);
            reject(message || 'Ein unbekannter Fehler ist aufgetreten');
          }
        );
      } catch(e) {
        reject(e.message);
      }
    });
  }

  loadUrl(url: string, data: any = {}) {
    return new Promise((resolve, reject) => {
      try {
        this.http.get(this.mainApiUrl+url)
        .subscribe(
          (response: any) => {
            if(!response) {
              reject('Ein unbekannter Fehler ist aufgetreten');
            } else
            if(response.success !== false && !response.message) {
              resolve(response);
            } else {
              reject(response.message || 'Ein unbekannter Fehler ist aufgetreten');
            }
          },
          (error: any) => {
            let message = error.message;
            if(message.indexOf('Http failure') === 0 || message.indexOf('http failure') === 0) {
              message = 'Wir arbeiten aktuell an unserem Lieferdienst und sind in Kürze wieder da. Bitte versuche es in Kürze erneut.';
            }
            console.warn('error', error);
            reject(message || 'Ein unbekannter Fehler ist aufgetreten');
          }
        );
      } catch(e) {
        reject(e.message);
      }
    });
  }

  postPluginData(plugin, data = {}) {
    return new Promise((resolve, reject) => {
      try {
      let headers = new Headers({ 'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, DELETE',
        // 'Access-Control-Allow-Headers': 'Accept,Accept-Language,Content-Language,Content-Type',
        // 'Access-Control-Expose-Headers': 'Content-Length,Content-Range' 
      });
      // let headers = new Headers({
      //     "Content-Type": "application/x-www-form-urlencoded; charset=utf-8", 
      //     'Accept': 'application/json, text/plain',
      //     "cache-control": "no-cache", 
      //     "Access-Control-Allow-Origin": "*", 
      //     "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
      //     "Access-Control-Allow-Credentials" : "true",
      //     "Access-Control-Allow-Methods" : "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",  
      // });
      this.http.post(this.mainApiUrl+plugin, data,{ headers: headers as any })
      .subscribe((response: any) => {
        resolve(response);
      });
      } catch(e) {
        // alert(e)
        reject(e.message);
      }
    });
  }

  putPluginData(plugin, data = {}) {
    return new Promise((resolve, reject) => {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      this.http.put(this.mainApiUrl+plugin, data, { headers: headers as any })
      .subscribe((response: any) => {
        resolve(response);
      });
    });
  }

  resetApiUrl() {
    this.apiUrl = this._apiUrl || this.getMainApiUrl();
  }

  setApiUrl(apiUrl: string) {
    if(!this._apiUrl) {
      this._apiUrl = '' + this.apiUrl;
    }
    this.apiUrl = apiUrl;
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiURL}${path}`, { params })
  }

  postFormData(path: string, body): Observable<any> {
    return this.http.post(
      `${environment.apiURL}${path}`,body
    );
  }
}
