import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(
    public AppCMS: AppcmsService,
  ) {

  }

  getAll() {
    return this.AppCMS.loadPluginData('forms', {});
  }

  getByUid(formId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('forms', {}, [formId])
      .then((form: form) => {
        if(form && form.uid) {
          resolve(form);
        } else
        if(form && form[0] && form[0].uid) {
          resolve(form[0]);
        } else {
          reject();
        }
      })
      .catch(reject);
    });
  }

  submit(form: form) {
    return this.AppCMS.loadPluginData('forms', {
      form: form
    }, ['submit', form.uid]);
  }

}
